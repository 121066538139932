import { CODENAME } from './codename';

// ADD NEW DOCUMENT TYPE CHANGES HERE

export const LOCAL_STORAGE_KEYS_TEST_FEATURES = {
  showDeprecatedEmbedCode: `${CODENAME}-show-deprecated-embed-code`,
  testFeatureExample1: `${CODENAME}-test-feature-example-1`,
  testFeatureExample2: `${CODENAME}-test-feature-example-2`,
};

export type LocalStorageKeysTestFeatures = keyof typeof LOCAL_STORAGE_KEYS_TEST_FEATURES;

export const LOCAL_STORAGE_KEYS = {
  aiInterviewLimit: `${CODENAME}-ai-interview-limit`,
  analyticsDebug: `${CODENAME}-analytics-debug`,
  devFTGraphql: `${CODENAME}-dev-ft-graphql`,
  devGraphql: `${CODENAME}-dev-graphql`,
  devInterfaceLanguage: `${CODENAME}-dev-interface-language`,
  devMenu: `${CODENAME}-dev-menu`,
  devNavigatorLanguage: `${CODENAME}-dev-navigator-language`,
  devNavigatorLanguageAll: `${CODENAME}-dev-navigator-language-all`,
  devReferrer: `${CODENAME}-dev-referrer`,
  devReferrerAll: `${CODENAME}-dev-referrer-all`,
  devShowLogs: `${CODENAME}-dev-show-logs`,
  devTraceLogs: `${CODENAME}-dev-trace-logs`,
  disableStrictMode: `${CODENAME}-disable-strict-mode`,
  errorDebug: `${CODENAME}-error-debug`,
  eventEmitterDebug: `${CODENAME}-event-emitter-debug`,
  gtmDebug: `${CODENAME}-gtm-debug`,
  interviewCheckData: `${CODENAME}-interview-check-data`,
  navDebug: `${CODENAME}-nav-debug`,
  newRelicDebug: `${CODENAME}-new-relic-debug`,
  seenFtOnBoardingWelcomeModal: `${CODENAME}-seen-ft-onboarding-welcome-modal`,
  seenFtShareSessionModal: `${CODENAME}-seen-ft-share-session-modal`,
  segmentDebug: `${CODENAME}-segment-debug`,
  selectedProductId: `${CODENAME}-selected-product-id`,

  // AI Interview >>
  aiInterviewLatestJobTitle: `${CODENAME}-ai-interview-latest-job-title`,
  aiInterviewPassedJobDescription: `${CODENAME}-ai-interview-passed-job-description`,
  // AI Interview <<

  // Expert Apply >>
  // key necessary for Portal teams using our Onboarding (e.g. MPR) to display a modal to the user on their part of the application after activation flow
  expertApplyFirstJobApplicationAutofill: `${CODENAME}-expert-apply-first-job-application-autofill`,
  expertApplyHasActivatedProfileBefore: `${CODENAME}-expert-apply-has-activated-profile-before`,

  // key set by Portal teams using our Onboarding (e.g. MPR) to get job based on id for us to show job banner and autofill the application at the end of the flow
  expertApplyRemoteJobId: `${CODENAME}-expert-apply-remote-job-id`,

  //  keys created by other teams >
  expertApplyPaymentStatus: 'paymentStatus',
  expertApplySubscriptionStatus: 'subscriptionStatus',
  //  keys created by other teams <
  // ExpertApply <<

  // experiments >>
  experiment: `${CODENAME}-experiment`,
  experimentsDebug: `${CODENAME}-experiments-debug`,
  experimentsOff: `${CODENAME}-experiments-off`,

  // Engagement improvements 1 onboarding
  seenFtEngagementImprovements1NewCommentModal: `${CODENAME}-seen-ft-engagement-improvements-1-new-comment-modal`,
  seenFtEngagementImprovements1OnboardingModal: `${CODENAME}-seen-ft-engagement-improvements-1-onboarding-welcome-modal`,

  ...LOCAL_STORAGE_KEYS_TEST_FEATURES,
};

export type LocalStorageKeys = keyof typeof LOCAL_STORAGE_KEYS;
